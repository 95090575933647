import React from "react";
import { AdSense, getAdSensePageType } from "./utils/adSenseUtils";
import { ValidTemplates } from "../commons/constants/ValidTemplates";
import SimpleLayout from "./layout/SimpleLayout";
import Logger from "../commons/Logger";
import { ErrorCode, EventType, QueryParam } from "../commons/Constants";
import { Settings } from "../config";
import PropTypes from "prop-types";
import { NoAdsPage } from "./NoAdsPage";
import postEvent, { createBaseLanderEvent } from "../commons/LanderEvent";
import { getContent } from "./content/Content";
import HegLayout from "./layout/HegLayout";
import ParkWebLayout from "./layout/ParkWebLayout";

// Using ES6 object destructuring to locally scope the variables without having to use props.landerConfig or props.queryConfig
// eslint-disable-next-line complexity
export default function AdSensePage({ landerConfig, queryConfig }) {
  // check if pubId and DRID are present. Proceed with CAF request regardless of DRID status
  const adSense = landerConfig.adSense;

  if (!adSense.drid || !adSense.pubId) {
    Logger.error("pubid or drid is missing");
    const errorCode = adSense.drid
      ? ErrorCode.ERROR_NO_PUBID
      : ErrorCode.ERROR_NO_DRID;
    sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig);

    // No ads but can render TDFS and other stuff
    return <NoAdsPage landerConfig={landerConfig} queryConfig={queryConfig} />;
  }

  if (!ValidTemplates.includes(landerConfig.lander.template)) {
    // This should not happen, we select a DEFAULT template while building landerConfig.
    Logger.warn("Unknown template " + landerConfig.lander.template);
  }
  // Get AdSense page (related link or sponsored listings)
  const page = getAdSensePageType(queryConfig);
  // Get localized content for a given template and locale
  const content = getContent(landerConfig.lander.template, landerConfig.system);

  switch (landerConfig.lander.template) {
    case AdSense.template.SIMPLE_2C:
    case AdSense.template.SIMPLE_2C_LIGHT:
    case AdSense.template.LIGHT_BLUE:
    case AdSense.template.VERTICAL_LINES:
    case AdSense.template.SIMPLE_2C_EXPERIMENT:
    case AdSense.template.ARROW_1:
    case AdSense.template.ARROW_2:
    case AdSense.template.ARROW_3:
      return (
        <SimpleLayout
          page={page}
          landerConfig={landerConfig}
          queryConfig={queryConfig}
          content={content}
        />
      );

    case AdSense.template.PARK_WEB:
      return (
        <ParkWebLayout
          page={page}
          landerConfig={landerConfig}
          queryConfig={queryConfig}
          content={content}
        />
      );
    case AdSense.template.PARK_WEB_HEG:
      return (
        <HegLayout
          page={page}
          landerConfig={landerConfig}
          queryConfig={queryConfig}
          content={content}
        />
      );
    default:
      Logger.warn("Unknown template " + landerConfig.lander.template);
      return (
        <SimpleLayout
          page={page}
          landerConfig={landerConfig}
          queryConfig={queryConfig}
          content={content}
        />
      );
  }
}

AdSensePage.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};

// Error event if lander-param content is invalid (missing pubId, drid, etc.)
function sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig) {
  const event = createBaseLanderEvent(EventType.ERROR, landerConfig);
  event.errorCode = errorCode;
  event.sToken = queryConfig[QueryParam.S_TOKEN];
  postEvent(Settings.EVENT_PUBLISH_API, event);
}
