import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classes from "./FooterParkWeb.module.scss";
import { PrivacyPolicy } from "./PrivacyPolicy";

export const FooterParkWeb = ({
  copyrightTextId,
  landerConfig,
  parkwebDisclaimerText = null,
}) => {
  return (
    <>
      <div id="copyright">
        <FormattedMessage
          id={copyrightTextId}
          values={{ currentYear: new Date().getFullYear() }}
        />
        <PrivacyPolicy landerConfig={landerConfig} />
      </div>
      {parkwebDisclaimerText && (
        <p className={classes.parkwebDisclaimerText}>
          <FormattedMessage id={parkwebDisclaimerText} />
        </p>
      )}
    </>
  );
};

FooterParkWeb.propTypes = {
  copyrightTextId: PropTypes.string,
  landerConfig: PropTypes.object,
  parkwebDisclaimerText: PropTypes.string,
};
