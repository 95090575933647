import { Settings } from "../config";
import "./uxcore-theme.css";

export const renderConsentManager = (w) => {
  w.ux = w.ux || {};
  w.ux.data = w.ux.data || {};

  w.ux.privacy = w.ux.privacy || {};
  w.ux.privacy.emulateUtag = true;
  w.ux.privacy.css = Settings.CONSENT_MANAGER_CSS;
  w.ux.privacy.scripts = { 0: [] };

  w.setTimeout(function () {
    var consentEl = document.createElement("script");
    consentEl.async = true;
    consentEl.src = Settings.CONSENT_MANAGER_JS;
    w.document
      .getElementsByTagName("script")[0]
      .parentNode.appendChild(consentEl);
  }, 0);
};
