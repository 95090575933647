import CssRule, { cssRule, cssRules } from "../../commons/CssRule";
import React from "react";
import { GoogleCaf } from "../utils/adSenseUtils";

export function getParkWebPageLevel() {
  return {
    uiOptimize: true,
    styleId: GoogleCaf.styleId.PARK_WEB,
  };
}

export function getParkWebRelatedLinks(mobile) {
  const obj = {
    attributionUppercase: true,
    attributionSpacingBelow: "6",
    attributionBold: true,
    webFontFamilyAttribution: "GD Sherpa",
    fontFamilyAttribution: "GD Sherpa,arial",
    fontSizeAttribution: "13",
    colorAttribution: "#111111",
    webFontFamily: "GD Sherpa",
    fontFamily: "GD Sherpa,arial",
    titleBold: false,
    fontSizeTitle: "18",
    lineHeightTitle: "50",
    noTitleUnderline: true,
    adIconUrl: "https://www.gstatic.com/domainads/images/chevron-white.png",
    adIconHeight: "18", // Required (if adIconUrl is set)
    adIconWidth: "18", // Required (if adIconUrl is set)
    adIconSpacingAbove: "10",
    adIconSpacingAfter: "10",
  };

  if (mobile) {
    obj.lineHeightTitle = "30";
  }

  return obj;
}

const verizonStyles = [
  new CssRule("#verizon-feed", [
    "width: 100%;",
    "max-width: 800px;",
    "padding: 1px,",
  ]),
  new CssRule("#verizon-feed span", ["color: #929292;"]),
  new CssRule("#verizon-feed a", [
    "text-decoration: none;",
    "color:  #111111;",
  ]),
  new CssRule("#verizon-feed .verizon-rs ul", ["border: 1px solid black;"]),
  new CssRule("#verizon-feed .verizon-ads", [
    "border: 1px solid black;",
    "padding: 8px;",
  ]),
  new CssRule("#verizon-feed .verizon-ads .adLink", [
    "text-decoration: underline;",
    "color: #0000EE;",
  ]),
];

export const commonRulesArr = [
  new CssRule("body", ["margin: 0;", "padding: 0;"]),
  new CssRule("#contentLayout", [
    "color: #111111;",
    "font-family: GD Sherpa,arial;",
  ]),
  new CssRule("#domainBanner", [
    "background-color: #D8EEEF;",
    "margin-bottom: 20px;",
  ]),
  new CssRule("#gdLogo", ["padding-left: 10%;", "padding-top: 20px;"]),
  new CssRule("#domain", [
    "font-size: 48px;",
    "font-weight: 600;",
    "line-height: 60px;",
    "text-align: center;",
    "padding-bottom: 5px;",
  ]),
  new CssRule("#domainInfo", [
    "font-size: 20px;",
    "font-weight: 600;",
    "line-height: 28px;",
    "text-align: center;",
    "padding-bottom: 5px;",
  ]),
  new CssRule("#getButton", ["padding-top: 10px;", "padding-bottom: 20px;"]),
  new CssRule("#getButtonBox", [
    "background-color: #111111;",
    "color: white;",
    "font-size: 16px;",
    "width: 248px;",
    "margin: auto;",
    "text-align: center;",
  ]),
  new CssRule("#getButtonBoxLink", [
    "display: block;",
    "line-height: 50px;",
    "text-decoration: none;",
    "font-weight: bold;",
    "color: white",
  ]),
  new CssRule("#relatedLinks, #ads", [
    "border: solid #D6D6D6 1px;",
    "background-color: #FFFFFF;",
  ]),
  new CssRule("#copyright", [
    "width: 100%;",
    "padding-top: 50px;",
    "max-width: 1200px;",
    "text-align: center;",
    "font-size: 16px;",
    "margin: auto;",
    "margin-bottom: 20px;",
  ]),
  new CssRule("#privacy a", ["color: #111;", "text-decoration: none;"]),
  new CssRule("#privacy a:hover", [
    "color: #111;",
    "text-decoration: underline;",
  ]),
  new CssRule(".center", [
    "width: 100%;",
    "display: flex;",
    "justify-content: center;",
    "align-items: center;",
  ]),
  ...verizonStyles,
];

const adTileRules = [
  new CssRule("#adTile", [
    "border: 1px solid #D6D6D6;",
    "padding: 16px 14px;",
    "max-width: 512px;",
  ]),
  new CssRule("#adTile h1", [
    "font-size: 36px;",
    "text-align: center;",
    "font-weight: 600;",
  ]),
  new CssRule("#adTile p", [
    "font-size: 20px;",
    "text-align: center;",
    "color: #767676;",
  ]),
  new CssRule(".verticalTable", ["display: flex;", "flex-direction: column;"]),
  new CssRule(".adTileRow", [
    "display: flex;",
    "flex-direction: row;",
    "border-left: 1px solid #D6D6D6;",
    "border-right: 1px solid #D6D6D6;",
    "border-top: 1px solid #D6D6D6;",
    "padding: 12px;",
    "justify-content: space-between;",
    "align-items: center;",
    "font-size: 18px;",
    "color: #767676;",
  ]),
  new CssRule(".adTileRow:last-of-type", ["border-bottom: 1px solid #D6D6D6;"]),
  new CssRule(".adTileDomainLabel", [
    "flex-shrink: 2;",
    "display: flex;",
    "flex-direction: column;",
    "justify-content: center;",
    "margin-right: 4px;",
    "min-width: 0px;",
    "max-width: 50%;",
    "height: 40px;",
  ]),
  new CssRule(".adTileDomain", [
    "max-width: 100%;",
    "overflow-x: hidden;",
    "text-overflow: ellipsis;",
    "white-space: nowrap;",
  ]),
  new CssRule(".adTilePromoted", ["font-size: 12px;"]),
  new CssRule(".adTilePrices", [
    "display: flex;",
    "flex-direction: row;",
    "align-items: baseline;",
  ]),
  new CssRule(".crossedPrice", [
    "text-decoration: line-through;",
    "font-size: 14px;",
    "margin-right: 8px;",
  ]),
  new CssRule(".salePrice", [
    "color: #00A4A6;",
    "font-weight: bold;",
    "font-size: 16px;",
    "margin-right: 12px;",
  ]),
];

export function commonRules() {
  return cssRules(commonRulesArr.concat(adTileRules));
}

export function ParkWeb() {
  return (
    <style>
      {commonRules()}
      {cssRule("#relatedLinks, #ads", [
        "margin: 0px;",
        "width: 788px;",
        "min-width: 0px;",
        "max-width: 788px;",
        "border: solid #D6D6D6 1px;",
        "padding: 16px 32px 32px;",
      ])}
      {cssRule(".adRow > *", ["margin: 0px 20px 0px 20px;"])}
      {cssRule(".adRow", [
        "width: 100%;",
        "display: flex;",
        "flex-direction: row;",
        "justify-content: center;",
        "align-items: start;",
      ])}
    </style>
  );
}

export function ParkWebMobile() {
  return (
    <style>
      {commonRules()}
      {cssRule("#gdLogo", [
        "padding-left: 0;",
        "padding-top: 17px;",
        "text-align: center;",
      ])}
      {cssRule("#domainBanner", [
        "margin-bottom: 10px;",
        "padding-bottom: 10px;",
      ])}
      {cssRule("#domain", [
        "font-size: 24px;",
        "font-weight: 500;",
        "line-height: 28px;",
        "margin-top: 12px;",
      ])}
      {cssRule("#domainInfo", [
        "padding-left: 5px;",
        "padding-right: 5px;",
        "margin-top: 7px;",
      ])}
      {cssRule("#getButton", ["padding-bottom: 10px;"])}
      {cssRule("#getButtonBox", ["width: 340px;"])}
      {cssRule("#getButtonBoxLink", ["font-weight: normal;"])}
      {cssRule("#relatedLinks, #ads", [
        "width: 360px;",
        "border: solid #D6D6D6 1px;",
        "padding: 0;",
      ])}
      {cssRule("#copyright", [
        "min-width: 0;",
        "margin-top: 50px;",
        "width: 346px;",
        "text-align: center;",
        "font-size: 13px;",
        "line-height: 20px;",
        "margin: auto;",
      ])}
      {cssRule(".adRow", [
        "width: 100%;",
        "display: flex;",
        "flex-direction: column;",
        "justify-content: start;",
        "align-items: center;",
      ])}
      {cssRule("#adTile", ["max-width: 100%"])}
    </style>
  );
}
