import { useEffect, useState } from "react";
import { getCookie } from "./HelperFunctions";

// Get consent value from cookie set by Consent Manager.
// Returns false if we don't have consent. Defaults to true
// https://godaddy-corp.atlassian.net/wiki/spaces/CKPT/pages/92317818/Customer+Consent+Cookie+OPTOUTMULTI
export const useCustomerConsent = () => {
  const [consent, setConsent] = useState(() => getAdvertisingConsent());
  useEffect(() => {
    const interval = setInterval(() => {
      setConsent(getAdvertisingConsent());
    }, 100);
    return () => clearInterval(interval);
  }, []);
  return consent;
};

function getAdvertisingConsent() {
  const consent = getCookie("OPTOUTMULTI");
  const consentArray = consent.split("%7C")[1] ?? "";
  return !consentArray.endsWith("1");
}
