import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "html-react-parser";
import Logger from "../../commons/Logger";
import { CopyrightCashParking } from "./CopyrightCashParking";
import { PrivacyPolicy } from "./PrivacyPolicy";

export const FooterCashParking = ({ landerConfig, queryConfig }) => {
  let footerElement;
  if (landerConfig.lander.footerHtml) {
    let decodedHtml = "";
    try {
      decodedHtml = window.atob(landerConfig.lander.footerHtml);
    } catch (e) {
      Logger.error("footerHtml value is not properly encoded" + toString(e));
    }
    footerElement = (
      <div className="footerLine footerColor">
        <div id="footerElement">{ReactHtmlParser(decodedHtml)}</div>
      </div>
    );
  } else if (landerConfig.lander.footerText) {
    footerElement = (
      <div className="footerLine footerColor">
        <div id="footerElement">{landerConfig.lander.footerText}</div>
      </div>
    );
  }

  return (
    <div id="footer">
      {footerElement}
      <div className="footerLine footerColor">
        <CopyrightCashParking
          landerConfig={landerConfig}
          queryConfig={queryConfig}
        />
        | &nbsp; <PrivacyPolicy landerConfig={landerConfig} />
      </div>
    </div>
  );
};

FooterCashParking.propTypes = {
  landerConfig: PropTypes.object,
  queryConfig: PropTypes.object,
};
