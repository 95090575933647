import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { sendBannerClickEvent } from "../../banner/Banner";

export default function GetDomainButton({ landerConfig }) {
  const link = landerConfig.lander?.banner?.link;
  if (!link) {
    return null;
  }
  const bannerType = landerConfig?.lander?.banner?.type;
  return (
    <div id={"getButton"}>
      <div id={"getButtonBox"}>
        <a
          id={"getButtonBoxLink"}
          href={link}
          onClick={() => sendBannerClickEvent(bannerType, landerConfig)}
        >
          <FormattedMessage id="GetThisDomainCTA" />
        </a>
      </div>
    </div>
  );
}

GetDomainButton.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};
