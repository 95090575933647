import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import LayoutStyle from "./LayoutStyle";
import GetDomainButton from "../commonComponents/GetDomainButton";
import { isBannerEnabled, getParkWebHegBannerText } from "../../banner/Banner";
import { ReactComponent as Reglogo } from "./123Reg.svg";
import { AdContent } from "../AdContent";
import { FooterParkWeb } from "../footer/FooterParkWeb";

export default class HegLayout extends Component {
  render() {
    const landerConfig = this.props.landerConfig;
    const queryConfig = this.props.queryConfig;
    const page = this.props.page;
    const content = this.props.content;

    const domainContent = isBannerEnabled(landerConfig) ? (
      <div>
        <Domain landerConfig={landerConfig} />
        <DomainInfo landerConfig={landerConfig} content={content} />
        <GetDomainButton landerConfig={landerConfig} />
      </div>
    ) : null;

    return (
      <div id={"contentLayout"}>
        <LayoutStyle template={landerConfig.lander.template} />
        <div id={"domainBanner"}>
          <div id={"headerLogoWrapper"}>
            <Reglogo />
          </div>

          <div id={"headerText"}>
            <div id={"headerTextTitle"}>
              {landerConfig?.lander?.domainDisplayName}
            </div>
            {domainContent}
          </div>
          <div id={"headerFooter"}>
            <FormattedMessage
              id="hegFooterText"
              values={{
                hegFooterTextHegSiteLink: (
                  <a
                    href="https://www.123-reg.co.uk/"
                    rel="nofollow"
                    style={{ color: "inherit" }}
                  >
                    <FormattedMessage id="hegFooterTextHegSiteLink" />
                  </a>
                ),
              }}
            />
          </div>
        </div>
        <div className="center">
          <AdContent
            landerConfig={landerConfig}
            queryConfig={queryConfig}
            page={page}
          />
        </div>
        <FooterParkWeb
          copyrightTextId="parkwebhegcopyrightText"
          landerConfig={landerConfig}
          parkwebDisclaimerText="parkwebhegDisclaimerText"
        />
      </div>
    );
  }
}

/**
 * @return {null || html}
 */
export function DomainInfo({ landerConfig, content }) {
  const text = getParkWebHegBannerText(landerConfig, content);
  if (typeof text === "undefined" || text === "") {
    return null;
  }
  return <div id={"domainInfo"}>{text}</div>;
}
DomainInfo.propTypes = {
  landerConfig: PropTypes.object,
  content: PropTypes.object,
};

function Domain({ landerConfig }) {
  const domain = landerConfig?.domain?.rootDomainDisplayText;
  if (!domain) {
    return null;
  }
  return <div id={"domain"}>{domain}</div>;
}

Domain.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};

// queryConfig is a required parameter in getDefaultRelatedLinkOptions
HegLayout.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};
